<style>
    header {
        display: flex;
        background: rgb(255, 214, 53);
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: fit-content;
        margin-top: 1rem;
        border-radius: 0.5rem;
        padding: 0.5rem;
    }

    h1, p {
        color: black;
        font-family: 'Stick No Bills', sans-serif;
        margin: 0;
        font-weight: 200;
    }
    h1 {
        font-size: 60px;
    }

    p {
        font-size: 20px;
    }
</style>

<header>
    <h1>arbestor</h1>
    <p>lover of human civilization, warts and all.</p>
    <p>type 1 civilization maximalist.</p>
</header>

